<template>
    <div class="abs-center wd-xxl mw-100 px-2 mt-5">
        <!-- START card-->
        <div class="text-center mb-3"><img src="img/layout/user-image.svg" width="64px"/></div>
        <div class="card card-default " :class="loading ? 'whirl' : ''">
            <div class="card-body card-body-solid" style="background-image: none;">
                <p class="text-center">{{$t('common.view.public.lock.heading')}}</p>
                <form @submit.prevent="validateBeforeSubmit('lock')" data-vv-scope="lock">
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input :class="{'form-control  border-right-0':true, 'is-invalid': errors.has('lock.password')}" v-model="lock.password" v-validate="'required'" type="password" name="password" :placeholder="$t('common.view.public.lock.password.placeholder')"/>
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fad fa-lock"></em>
                                </span>
                            </div>
                            <span v-show="errors.has('lock.password')" class="invalid-feedback">{{ errors.first('lock.password') }}</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mt-1">
                            <a @click.prevent="logout()" href="" class="text-white">
                                <small>{{$t('common.view.public.lock.logout')}}</small>
                            </a>
                        </div>
                        <div class="ml-auto">
                            <b-btn type="submit" variant="secondary" class="text-uppercase">{{$t('common.view.public.lock.button.unlock')}}</b-btn>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                lock: {
                    password: '',
                    verification_code: '',
                },
                loading: false
            }
        },
      beforeMount() {
        if (!this.$store.state.loginMail)  {
          return this.$router.push({name: 'login'});
        }
      },
        methods: {
            validateBeforeSubmit(scope) {
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        this.loading = true;
                        this.$api.post('/auth/login', {
                            email: this.$store.state.loginMail,
                            password: this.lock.password,
                            verification_code: this.lock.verification_code
                        }).then(response => {
                            this.loading = false;
                            this.$auth.unlock(response.data);
                        }).catch(error => {
                            this.loading = false;
                            if (error.status === 401) {
                                if (error.data.action === 'request_2fa') {
                                    this.$swal.fire({
                                        title: 'Two Factor Authentication',
                                        input: 'text',
                                        inputPlaceholder: 'Enter your one time password here'
                                    }).then(response => {
                                        this.lock.verification_code = response.value;
                                        this.validateBeforeSubmit(scope);
                                    });
                                } else {
                                    this.$swal.fire('wrong credentials', '', 'error');
                                }
                            }
                        });
                    }
                });
            },
            logout() {
                this.$store.commit('logoutUser');
                this.$router.push({name: 'Login'});
            }
        }
    }
</script>
